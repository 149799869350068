import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiCard } from '@uireact/card';
import { UiFlexGrid } from '@uireact/flex';
import { UiText } from '@uireact/text';
import Playground from '../../../src/Playground';
import { UiButton } from '../src/';
import { UiSpacing, Sizing } from '../../foundation/src/';
import { UiIcon } from '../../icons/src';
import { UiGrid } from '../../grid/src/';
import * as packageJson from '../package.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "uibutton"
    }}>{`UiButton`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/button/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`An actionable element, commonly used in forms`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/button`}</p>
    </blockquote>
    <h2 {...{
      "id": "uibutton-1"
    }}>{`UiButton`}</h2>
    <Playground __position={1} __code={'<UiButton>\n  <UiSpacing margin={{ all: Sizing.three }}>Button! 💅🏾</UiSpacing>\n</UiButton>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiFlexGrid,
      UiText,
      Playground,
      UiButton,
      UiSpacing,
      Sizing,
      UiIcon,
      UiGrid,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiButton mdxType="UiButton">
    <UiSpacing margin={{
          all: Sizing.three
        }} mdxType="UiSpacing">Button! 💅🏾</UiSpacing>
  </UiButton>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiButton} mdxType="Props" />
    <h3 {...{
      "id": "more-examples"
    }}>{`More examples`}</h3>
    <h4 {...{
      "id": "with-fullwidth"
    }}>{`With fullWidth`}</h4>
    <Playground __position={3} __code={'<UiGrid cols={2} rows={1} colsGap=\"four\" justifyItems=\"center\">\n  <UiButton fullWidth padding={{ all: \'three\' }}>\n    Button! 💅🏾\n  </UiButton>\n  <UiButton fullWidth category=\"tertiary\" padding={{ all: \'three\' }}>\n    Button! 💅🏾\n  </UiButton>\n</UiGrid>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiFlexGrid,
      UiText,
      Playground,
      UiButton,
      UiSpacing,
      Sizing,
      UiIcon,
      UiGrid,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiGrid cols={2} rows={1} colsGap='four' justifyItems="center" mdxType="UiGrid">
    <UiButton fullWidth padding={{
          all: 'three'
        }} mdxType="UiButton">
      Button! 💅🏾
    </UiButton>
        <UiButton fullWidth category="tertiary" padding={{
          all: 'three'
        }} mdxType="UiButton">
  Button! 💅🏾
        </UiButton>
  </UiGrid>
    </Playground>
    <h4 {...{
      "id": "as-submit-button"
    }}>{`As submit button`}</h4>
    <Playground __position={4} __code={'<form>\n  <UiButton theme=\"secondary\" type=\"submit\">\n    Button! 💅🏾\n  </UiButton>\n</form>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiFlexGrid,
      UiText,
      Playground,
      UiButton,
      UiSpacing,
      Sizing,
      UiIcon,
      UiGrid,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <form>
    <UiButton theme="secondary" type="submit" mdxType="UiButton">
      Button! 💅🏾
    </UiButton>
  </form>
    </Playground>
    <h4 {...{
      "id": "button-themes"
    }}>{`Button themes:`}</h4>
    <Playground __position={5} __code={'<UiGrid\n  cols={{ small: 1, medium: 5, large: 7, xlarge: 7 }}\n  colsGap=\"three\"\n  rowsGap=\"three\"\n  justifyItems=\"center\"\n>\n  <UiButton fullWidth padding={{ all: \'three\' }}>\n    Button! 🐬\n  </UiButton>\n  <UiButton fullWidth category=\"secondary\" padding={{ all: \'three\' }}>\n    Button! 🐉\n  </UiButton>\n  <UiButton fullWidth category=\"tertiary\" padding={{ all: \'three\' }}>\n    Button! 🌹\n  </UiButton>\n  <UiButton fullWidth category=\"positive\" padding={{ all: \'three\' }}>\n    Button! 🌎\n  </UiButton>\n  <UiButton fullWidth category=\"negative\" disabled padding={{ all: \'three\' }}>\n    Button! 🌈\n  </UiButton>\n  <UiButton fullWidth category=\"error\" padding={{ all: \'three\' }}>\n    Button! ⚡️\n  </UiButton>\n  <UiButton fullWidth category=\"warning\" padding={{ all: \'three\' }}>\n    Button! 🌋\n  </UiButton>\n</UiGrid>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiFlexGrid,
      UiText,
      Playground,
      UiButton,
      UiSpacing,
      Sizing,
      UiIcon,
      UiGrid,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiGrid cols={{
        small: 1,
        medium: 5,
        large: 7,
        xlarge: 7
      }} colsGap="three" rowsGap="three" justifyItems="center" mdxType="UiGrid">
    <UiButton fullWidth padding={{
          all: 'three'
        }} mdxType="UiButton">
      Button! 🐬
    </UiButton>
    <UiButton fullWidth category="secondary" padding={{
          all: 'three'
        }} mdxType="UiButton">
      Button! 🐉
    </UiButton>
    <UiButton fullWidth category="tertiary" padding={{
          all: 'three'
        }} mdxType="UiButton">
      Button! 🌹
    </UiButton>
    <UiButton fullWidth category="positive" padding={{
          all: 'three'
        }} mdxType="UiButton">
      Button! 🌎
    </UiButton>
    <UiButton fullWidth category="negative" disabled padding={{
          all: 'three'
        }} mdxType="UiButton">
      Button! 🌈
    </UiButton>
    <UiButton fullWidth category="error" padding={{
          all: 'three'
        }} mdxType="UiButton">
      Button! ⚡️
    </UiButton>
    <UiButton fullWidth category="warning" padding={{
          all: 'three'
        }} mdxType="UiButton">
      Button! 🌋
    </UiButton>
  </UiGrid>
    </Playground>
    <h4 {...{
      "id": "rounded-button"
    }}>{`Rounded button`}</h4>
    <Playground __position={6} __code={'<UiCard weight=\"200\" category=\"positive\">\n  <UiFlexGrid gap=\"three\">\n    <UiButton rounded>\n      <UiText>Button 🌜</UiText>\n    </UiButton>\n    <UiButton rounded styling=\"outlined\" category=\"secondary\">\n      <UiText>Button 🍻</UiText>\n    </UiButton>\n    <UiButton rounded styling=\"clear\" category=\"tertiary\">\n      <UiText>Button 📡</UiText>\n    </UiButton>\n  </UiFlexGrid>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiFlexGrid,
      UiText,
      Playground,
      UiButton,
      UiSpacing,
      Sizing,
      UiIcon,
      UiGrid,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard weight="200" category="positive" mdxType="UiCard">
    <UiFlexGrid gap="three" mdxType="UiFlexGrid">
      <UiButton rounded mdxType="UiButton">
        <UiText mdxType="UiText">Button 🌜</UiText>
      </UiButton>
      <UiButton rounded styling="outlined" category="secondary" mdxType="UiButton">
        <UiText mdxType="UiText">Button 🍻</UiText>
      </UiButton>
      <UiButton rounded styling="clear" category="tertiary" mdxType="UiButton">
        <UiText mdxType="UiText">Button 📡</UiText>
      </UiButton>
    </UiFlexGrid>
  </UiCard>
    </Playground>
    <h4 {...{
      "id": "clear-button"
    }}>{`Clear button`}</h4>
    <Playground __position={7} __code={'<UiCard>\n  <UiFlexGrid gap=\"three\">\n    <UiButton category=\"tertiary\" styling=\"clear\" padding={{ all: \'three\' }}>\n      Button! ✨\n    </UiButton>\n    Some content\n  </UiFlexGrid>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiFlexGrid,
      UiText,
      Playground,
      UiButton,
      UiSpacing,
      Sizing,
      UiIcon,
      UiGrid,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard mdxType="UiCard">
    <UiFlexGrid gap="three" mdxType="UiFlexGrid">
      <UiButton category="tertiary" styling="clear" padding={{
            all: 'three'
          }} mdxType="UiButton">
        Button! ✨
      </UiButton>
      Some content
    </UiFlexGrid>
  </UiCard>
    </Playground>
    <h4 {...{
      "id": "icon-button"
    }}>{`Icon button`}</h4>
    <Playground __position={8} __code={'<UiCard weight=\"10\">\n  <UiFlexGrid gap=\"three\" alignItems=\"center\">\n    <UiButton styling=\"icon\" category=\"primary\">\n      <UiIcon icon=\"EarthAmericas\" size=\"xlarge\" />\n    </UiButton>\n    <UiButton styling=\"icon\" category=\"secondary\">\n      <UiIcon icon=\"EarthEuropa\" size=\"large\" />\n    </UiButton>\n    <UiButton styling=\"icon\" category=\"tertiary\">\n      <UiIcon icon=\"EarthAsia\" size=\"regular\" />\n    </UiButton>\n    <UiButton styling=\"icon\" category=\"positive\">\n      <UiIcon icon=\"EarthAfrica\" size=\"small\" />\n    </UiButton>\n    <UiButton styling=\"icon\" category=\"error\">\n      <UiIcon icon=\"BadgeCheck\" size=\"xsmall\" />\n    </UiButton>\n  </UiFlexGrid>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiFlexGrid,
      UiText,
      Playground,
      UiButton,
      UiSpacing,
      Sizing,
      UiIcon,
      UiGrid,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard weight="10" mdxType="UiCard">
    <UiFlexGrid gap="three" alignItems="center" mdxType="UiFlexGrid">
      <UiButton styling="icon" category="primary" mdxType="UiButton">
        <UiIcon icon="EarthAmericas" size="xlarge" mdxType="UiIcon" />
      </UiButton>
      <UiButton styling="icon" category="secondary" mdxType="UiButton">
        <UiIcon icon="EarthEuropa" size="large" mdxType="UiIcon" />
      </UiButton>
      <UiButton styling="icon" category="tertiary" mdxType="UiButton">
        <UiIcon icon="EarthAsia" size="regular" mdxType="UiIcon" />
      </UiButton>
      <UiButton styling="icon" category="positive" mdxType="UiButton">
        <UiIcon icon="EarthAfrica" size="small" mdxType="UiIcon" />
      </UiButton>
      <UiButton styling="icon" category="error" mdxType="UiButton">
        <UiIcon icon="BadgeCheck" size="xsmall" mdxType="UiIcon" />
      </UiButton>
    </UiFlexGrid>
  </UiCard>
    </Playground>
    <h4 {...{
      "id": "outlined-button"
    }}>{`Outlined button`}</h4>
    <Playground __position={9} __code={'<UiCard category=\"primary\" weight=\"10\">\n  <UiFlexGrid gap=\"three\">\n    <UiButton styling=\"outlined\" category=\"tertiary\">\n      <UiText>Button 🐼</UiText>\n    </UiButton>\n    <UiButton styling=\"outlined\" category=\"secondary\">\n      <UiText>\n        Button <UiIcon icon=\"Refresh\" />\n      </UiText>\n    </UiButton>\n    <UiButton styling=\"outlined\" rounded category=\"positive\">\n      <UiText>\n        Button <UiIcon icon=\"VideoCameraVintage\" />\n      </UiText>\n    </UiButton>\n  </UiFlexGrid>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiFlexGrid,
      UiText,
      Playground,
      UiButton,
      UiSpacing,
      Sizing,
      UiIcon,
      UiGrid,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard category="primary" weight="10" mdxType="UiCard">
    <UiFlexGrid gap="three" mdxType="UiFlexGrid">
      <UiButton styling="outlined" category="tertiary" mdxType="UiButton">
        <UiText mdxType="UiText">Button 🐼</UiText>
      </UiButton>
      <UiButton styling="outlined" category="secondary" mdxType="UiButton">
        <UiText mdxType="UiText">
          Button <UiIcon icon="Refresh" mdxType="UiIcon" />
        </UiText>
      </UiButton>
      <UiButton styling="outlined" rounded category="positive" mdxType="UiButton">
        <UiText mdxType="UiText">
          Button <UiIcon icon="VideoCameraVintage" mdxType="UiIcon" />
        </UiText>
      </UiButton>
    </UiFlexGrid>
  </UiCard>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      